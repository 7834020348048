.servicios_header {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 40vh;
    background-color: red;
    background: linear-gradient(
        rgba(0, 0, 0, 0.6), 
        rgba(0, 0, 0, 0.6)
      ),
      url(https://amazonical.com/wp-content/uploads/2020/12/Mango-deshidratado-54536127_s.jpg);
      background-size: cover;
      background-repeat: no-repeat;
}

.servicios_header span {
    font-size: 50px;
    color: var(--primary-container-light);
    font-weight: 1000;
}

.servicios_header h1 {
    font-variant: small-caps;
    font-size: 3.6rem;
    color: var(--just-white);
    font-weight: 1000;
    text-align: center;
    line-height: 25px;

    width: 50%;
}

.servicios_header p {
    font-variant: small-caps;
    font-size: 2.4rem;
    color: var(--just-white);
    font-weight: 1000;
    line-height: 20px;

    width: 70%;
    padding-bottom: 10px;
}

/* main */

.servicios_main {
    display: grid;
}

.service {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80vw;

    margin: 0 auto;
}

.service h1 {
    font-size: 3.6rem;
    color: var(--primary-light);
    font-weight: 1000;
    padding-top: 20px;
}

.service p {
    font-size: var(--paragraph-font-size-M);
    text-align: justify;
    padding-top: 10px;
}

.service img {
    width: 80vw;
    height: auto;
    border-radius: 20px;
    padding-top: 10px;
}

.servicios_form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: 0 auto;
    padding-top: 30px;
    width: 100vw;
}

.servicios_form h2 {
    font-size: 3.6rem;
    color: var(--primary-dimmed-light);
    font-weight: 1000;
    padding-top: 20px;

    text-align: center;
}

.servicios_form__box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    height: 50px;

    border: 2px solid var(--primary-light);
    border-radius: 10px;
    margin-top: 5px;

    font-size: var(--paragraph-font-size-M);
    color: black;
    padding-left: 20px;
    font-family: 'Nunito Sans' , sans-serif;
    font-weight: bold;
}

.servicios_form__select {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    height: 50px;

    border: 2px solid var(--primary-light);
    border-radius: 10px;
    margin-top: 5px;

    font-size: var(--paragraph-font-size-M);
    color: #526350;
    padding-left: 20px;
    font-family: 'Nunito Sans' , sans-serif;
    font-weight: bold;
    background-color: transparent;
}

.servicios_form__textArea {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    height: 300px;

    border: 2px solid var(--primary-light);
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 15px;

    font-size: var(--paragraph-font-size-M);
    color: black;
    padding-left: 20px;
    padding-top: 20px;
    font-family: 'Nunito Sans' , sans-serif;
    font-weight: bold;
    background-color: transparent;
}

.servicios_form__textArea::placeholder {
    color: #526350;
}

.servicios_form__button {
    width: 150px;
    height: 50px;
    margin-bottom: 30px;
}

.servicios_form__button p {
    font-weight: bold;
}

@media (min-width: 800px) {
    .service {
        width: 40vw;
    }
    
    .service img {
        width: 40vw;
        height: auto;
    }
    
    .servicios_form {
        width: 40vw;
    }
    
    .servicios_form__box {
        width: 40vw;
    }
    
    .servicios_form__select{
        width: 40vw;
    }
    
    .servicios_form__textArea {
        width: 40vw;
    }
    
    .service {
        align-items: center;
    }
    
    .service h1 {
        text-align: center;
    }
}