.NavCMS {
    display: grid;
    grid-template-columns: 250px 1fr;
    width: 250px;
    min-height: 100vh;
    position: fixed;
}

.NavCMS_item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    text-align: left;
    text-decoration: none;
    color: #002106;
    width: 90%;
    margin: 10px;
}

.especial h1 {
    background-color: #F1F6ED;
}

.NavCMS_item h1 {
    font-size: 2rem;
    font-weight: 800;
    margin: 0;
    text-align: center;
    border-radius: 10px;

    width: 180px;
}

.NavCMS_item span {
    font-size: 3.5rem;
    color: #206C2E;
}


.NavCMS_Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #D5E8D0;
}