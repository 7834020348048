.CreateView_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  background-color: #E4EDE1;

  margin-left: 50%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.CreateView_Title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  width: 70%;
}

.CreateView_Title h1 {
  font-size: 3.2rem;
  font-weight: 700;
  color: #0B5C21;
  font-weight: 800;
  margin-bottom: 0;
}

.CreateView_Title span {
  font-size: 3.2rem;
  color: black;

  margin-right: 20px;
  cursor: pointer;
}

.CreateView_boxes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  width: 100%;
}