.InputCMS {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
    width: 70%;
    min-height: 150px ;

    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.InputCMS h1 {
    font-size: 2.4rem;
    font-weight: 600;
    color: #2F2F2F;
    text-transform: uppercase;
}

.InputCMS textarea {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;

    background-color: #B9CCB4;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;

    border: none;
    resize: vertical;

    font-family: inherit;
    font-size: 1.6rem;
}