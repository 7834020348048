.button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-container-light);
    border: none;
    border-radius: 5px;
    color: var(--just-white);
    font-size: var(--small-font-size-M);
    border-radius: 20px;
    width: auto;
    cursor: pointer;

    margin-top: 20px;
}

.button p {
    font-variant: normal;
    color: var(--on-primary-container-light);
    font-size: 1.6rem;
    font-weight: 1000;
    text-align: center;

    padding: 10px 20px;
}