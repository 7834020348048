.NoticiasCMS_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    min-height: 100vh;
    background-color: #E4EDE1;

    margin-left: 50%;
}