.noticiaV-main {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    background-color: var(--surface-light);
}

.noticiaV-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 70px;
}

.noticiaV-title h1 {
    font-size: 4rem;
    line-height: 40px;
    font-weight: 1000;
    color: var(--primary-light);
    text-align: left;
    padding: 0 10vw;
}

.noticiaV-title p {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--just-black);
    text-align: justify;
    padding: 0 10vw;
}

.noticiaV-regresar {
    font-size: 1.4rem;
    color: var(--primary-light);
    text-align: justify;
    padding: 0 10vw;
}

.share__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.share__buttons a {
  color: black;
  text-decoration: none;
  font-size: 40px;
  margin: 0 10px;
}

.noticiaV-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    padding: 0 10vw;
}

.noticiaV-content p {
    font-size: 1.6rem;
    font-weight: normal;
    color: var(--just-black);
    text-align: justify;
    text-indent: 20px;
}

.noticiaV-content img {
    width: 100%;
    height: auto;
    margin: 20px 0;
    border-radius: 10px;
}

@media (min-width: 600px) {
    .noticiaV-main {
        display: flex;
        flex-direction: column;
        background-color: var(--surface-light);
    }

    .noticiaV-title {
        margin-top: 70px;
    }

    .noticiaV-title h1 {
        font-size: 4rem;
        font-weight: 1000;
        color: var(--primary-light);
        text-align: left;
        padding: 0 10vw;
        text-align: center;
    }

    .noticiaV-title p {
        font-size: 1.8rem;
        font-weight: bold;
        color: var(--just-black);
        text-align: justify;
        padding: 0 10vw;
        text-align: center;
    }

    .noticiaV-regresar {
        font-size: 1.8rem;
        color: var(--primary-light);
        text-align: justify;
        padding: 0 10vw;
        text-align: center;
    }

    .noticiaV-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        padding: 0 10vw;
    }

    .noticiaV-content p {
        font-size: 1.8rem;
        font-weight: normal;
        color: var(--just-black);
        text-align: justify;
        text-indent: 20px;
        width: 80%;
    }

    .noticiaV-content img {
        width: 80%;
        height: auto;
        margin: 20px 0;
        border-radius: 10px;
    }
}

@media (min-width: 800px) {
    .noticiaV-main {
        display: flex;
        flex-direction: column;
        background-color: var(--surface-light);
    }

    .noticiaV-title {
        margin-top: 70px;
    }

    .noticiaV-title h1 {
        font-size: 5rem;
        font-weight: 1000;
        color: var(--primary-light);
        text-align: left;
        padding: 0 10vw;
        text-align: center;
    }

    .noticiaV-title p {
        font-size: 2.4rem;
        font-weight: bold;
        color: var(--just-black);
        text-align: justify;
        padding: 0 10vw;
        text-align: center;
    }

    .noticiaV-regresar {
        font-size: 2.4rem;
        color: var(--primary-light);
        padding: 0 10vw;
        text-align: center;
    }

    .noticiaV-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        padding: 0 10vw;
    }

    .noticiaV-content p {
        font-size: 2.4rem;
        font-weight: normal;
        color: var(--just-black);
        text-align: justify;
        text-indent: 20px;
        width: 60%;
    }

    .noticiaV-content img {
        width: 60%;
        height: auto;
        margin: 20px 0;
        border-radius: 10px;
    }
}

