.comiteCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;

    padding-top: 15px;
    padding-bottom: 30px;
}

.comiteCard img {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    object-fit: cover;
}

.comiteCard h2 {
    font-size: var(--subtitle-font-size-M);
    color: var(--primary-light);
    text-align: center;
    padding-top: 10px;
    font-weight: bold;
}

.comiteCard p {
    font-size: var(--paragraph-font-size-M);
    color: var(--primary-dimmed-light);
    text-align: center;
}