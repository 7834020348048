.CMS_Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
}

@media (min-width: 768px) {  
  .CMS_Container {
      display: grid;
      grid-template-columns: 250px 1fr;
      min-height: 100vh;
      background-color: #E4EDE1;
  }
}