.nav.colorful {
    background: #002106;
}

.nav.colorfulScroll {
    background: #002106;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 60px;
    background: #002106;
    background: none;

    overflow: hidden;
    position: fixed;
    z-index: 1;
}

#inactive {
    display: none;
}

#transparent {
    background-color: transparent;
}

#color {
    background-color: rgb(0, 33, 6);
}

.nav__bar span {
    font-size: 30px;
    padding-right: 30px;
    color: white;
}

.nav__home span {
    font-size: 30px;
    padding-left: 20px;
    color: white;
}

.nav__home img {
    width: 70px;
    height: auto;
    padding-left: 20px;
}

.nav__menuMobile.visible {
    display: flex;
}

.nav__menuMobile {
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
    background-color:#002106;
    top: 50px;

    padding: 10px 0;

    overflow: hidden;
    position: fixed;
    
    z-index: 1;
}

.nav-menuMobile__element {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    height: 50px;
    background-color: #002106;

    padding-left: 20px;
    text-decoration: none;
}

.nav-menuMobile__element span {
    font-size: 30px;
    padding-right: 10px;
    color: white;
}

.nav-menuMobile__element p {
    font-size: 1.6rem;
    color: white;
    font-weight: bold;
}

.nav__menuDesktop {
    display: none;
}

@media (min-width: 600px) {
    .nav__menuDesktop {
        display: flex;
        padding-right: 30px;
    }

    .nav__home img {
        width: 80px;
        height: auto;
        padding-left: 20px;
    }

    .nav__menuDesktop a {
        text-decoration: none;
        color: white;
        font-size: 1.6rem;
        font-weight: bold;
        padding: 0 20px;
    }

    .nav__menuDesktop a:hover {
        color: #A6F5A7;
    }

    .nav__bar {
        display: none;
    }
}

@media (min-width: 800px) {
    .nav__bar {
        display: none;
    }

    .nav__menuDesktop {
        display: flex;
        padding-right: 30px;
    }

    .nav__home img {
        width: 80px;
        height: auto;
        padding-left: 20px;
    }

    .nav__menuDesktop a {
        text-decoration: none;
        color: white;
        font-size: 1.6rem;
        font-weight: bold;
    }

    .nav__menuDesktop a:hover {
        color: #A6F5A7;
    }
}