.noticias_header {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 40vh;
    background-color: red;
    background: linear-gradient(
        rgba(0, 0, 0, 0.6), 
        rgba(0, 0, 0, 0.6)
      ),
      url(https://cdn.forbes.com.mx/2018/05/Sagarpa-1-e1527267710462.jpg);
      background-size: cover;
      background-repeat: no-repeat;
}

.noticias_header span {
    font-size: 50px;
    color: var(--primary-container-light);
    font-weight: 1000;
}

.noticias_header h1 {
    font-variant: small-caps;
    font-size: 3.6rem;
    color: var(--just-white);
    font-weight: 1000;
    text-align: center;
    line-height: 40px;
}

.noticias_header p {
    font-variant: small-caps;
    font-size: 2.4rem;
    color: var(--just-white);
    font-weight: 1000;
    line-height: 20px;

    width: 70%;
    padding-bottom: 10px;
}

.main_noticias {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    align-items: center;
    justify-items: center;
}

.main_noticias h2 {
    font-size: 2.8rem;
    color: var(--primary-light);
    font-weight: 1000;
    text-align: center;
    margin-top: 30px;
}