.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    color: white;
    background-color: var(--surface-dark);
}

.footer span {
    font-size: 6rem;
    color: var(--just-white);
    padding-left: 50px;
    padding-top: 30px;
}

.footer__logo {
  padding-left: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 150px;
  height: auto;
}

.footer__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    text-align: justify;
    padding-left: 50px;
    font-size: var(--paragraph-font-size-M);
}

.footer__contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
    padding-left: 50px;
    padding-top: 15px;
    font-size: var(--paragraph-font-size-M);
}

.footer__contact p {
    font-size: 1.4rem;
    text-align: center;
    font-weight: 300;
}

.footer__sections {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 15px;
}

.footer__sections a {
    text-align: center;
    font-size: var(--paragraph-font-size-M);
    text-decoration: none;
    color: #E2E3DD;
    font-weight: 900;
    margin: 0 10px;
}

.footer__rights {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.2rem;
    font-weight: 100;
}

#footer__year {
    font-size: 1.2rem;
    font-weight: 100;
    padding: 0;
}