.nosotros_header {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 40vh;
    background-color: red;
    background: linear-gradient(
        rgba(0, 0, 0, 0.6), 
        rgba(0, 0, 0, 0.6)
      ),
      url(https://cdn.forbes.com.mx/2018/05/Sagarpa-1-e1527267710462.jpg);
      background-size: cover;
      background-repeat: no-repeat;
}

.nosotros_header span {
    font-size: 50px;
    color: var(--primary-container-light);
    font-weight: 1000;
}

.nosotros_header h1 {
    font-variant: small-caps;
    font-size: 3.6rem;
    color: var(--just-white);
    font-weight: 1000;
    text-align: center;
    line-height: 40px;
}

.nosotros_header p {
    font-variant: small-caps;
    font-size: 2.4rem;
    color: var(--just-white);
    font-weight: 1000;
    line-height: 20px;

    width: 70%;
    padding-bottom: 10px;
}

.nosotros_header a {
    text-decoration: none;
}

.nosotros_main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
    "title"
    "image"
    "content"
    "comite";
}

.title {
    grid-area: title;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
}

.title h1 {
    font-size: var(--title-font-size-M);
    color: var(--primary-light);
    font-weight: 1000;
    text-align: center;
}

.title p {
    font-size: var(--subtitle-font-size-M);
    color: var(--primary-dimmed-light);
    font-weight: 1000;
    padding-bottom: 10px;
    text-align: center;
    width: 80%;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
    padding-top: 20px;
}

.image img {
    width: 280px;
    height: auto;
    border-radius: 20px;
}

.content {
    padding-top: 30px;
    grid-area: content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content p {
    font-size: var(--paragraph-font-size-M);
    color: var(--just-black);
    padding-bottom: 10px;
    text-align: justify;
    width: 80%;
}

.comite {
    grid-area: comite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    width: 100vw;
}

.comite__title {
    font-size: var(--title-font-size-M);
    color: var(--primary-light);
    font-weight: 1000;
    text-align: center;
    font-variant: small-caps;
}

/* section with horizontal slide  */

.comite__slider {
    display:flex;
    overflow: scroll;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;

    max-width: 100vw;
    padding-bottom: 30px;
}

.comite__slider::-webkit-scrollbar {
    display: none;
}

.comite__slider {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.comite-slider__member {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100vw;
    width: 200px;
    height: auto;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    position: relative;

    padding-top: 15px;
}

.comite-slider__member img {
    width: 120px;
    height: 120px;
    border-radius: 100%;
}

.comite-slider__member h2 {
    font-size: var(--subtitle-font-size-M);
    color: var(--primary-light);
    text-align: center;
    padding-top: 10px;
    font-weight: bold;
}

.comite-slider__member p {
    font-size: var(--paragraph-font-size-M);
    color: var(--primary-dimmed-light);
    text-align: center;
}

@media (min-width: 800px) {
    .image {
        width: 100vw;
    }
    
    .image img {
        width: 500px;
        height: auto;
    }
    
    .content p {
        width: 500px;
    }
}