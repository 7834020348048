.MainPageCMS_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    min-height: 100vh;
    background-color: #E4EDE1;

    margin-left: 50%;
}

.CMS__headers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 15px;
    width: 70%;
}

.anadir {
    display: flex;
    align-items: center;
}

.anadir span {
    font-size: 2.5rem;
    font-weight: 700;
    color: black;
    font-weight: 800;
    margin-bottom: 0;

    padding-left: 15px;
    cursor: pointer;
}

.CMS__headers h2 {
    font-size: 3.2rem;
    font-weight: 700;
    color: #0B5C21;
    font-weight: 800;
    margin-bottom: 0;
}