.Login_Main {
    background-color: #E4EDE1;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Login_Main form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    background-color: #D5E8D0;
    border-radius: 20px;
}

.Login_Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 570px;
    height: 700px;
    background-color: #D5E8D0;
    border-radius: 20px;
}

.Login_Container h1 {
    font-size: 4.8rem;
    width: 60%;
    text-align: center;
    font-weight: 800;
    color: #0B5C21;
    margin-bottom: 20px;
}

.Login_Container input {
    width: 350px;
    height: 50px;
    border-radius: 10px;
    border: none;
    margin-bottom: 10px;
    padding-left: 20px;
    font-size: 1.6rem;
    font-weight: 600;

    background-color: #B9CCB4;
}

.Login_Container input:focus {
    outline: none;
    border: none;
}

.Login_Error {
    font-size: 1.6rem;
    color: red;
    font-weight: bold;
    width: 60%;
    text-align: center;
}

.Login_Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 45px;
    border-radius: 20px;
    border: none;
    margin-bottom: 10px;
    font-size: 1.6rem;
    font-weight: 600;
    color: white;
    background-color: #526350;

    cursor: pointer;
}