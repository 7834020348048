.noticias__slider {
    display: flex;
    overflow: scroll;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;

    max-width: 100%;

    padding-top: 20px;
}

.noticias__slider a {
    margin: 0 5%;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 300px;
    height: 200px;
    min-width: 300px;
    background-size: cover;
    border-radius: 20px;
    cursor: pointer;

    position: relative;
    scroll-snap-align: center;

    margin: 15px 0;
}

.card__text {
    height: 30%;
    width: 100%;

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.card__text h3 {
    color: var(--just-white);
    font-size: var(--small-font-size-M);
    padding-left: 10px;
    padding-right: 10px;
}

.card__text p {
    color: var(--just-white);
    font-size: var(--smaller-font-size-M);
    padding-left: 10px;
}